const raw = `red
red
#F44336
red lighten-5
#FFEBEE
red lighten-4
#FFCDD2
red lighten-3
#EF9A9A
red lighten-2
#E57373
red lighten-1
#EF5350
red darken-1
#E53935
red darken-2
#D32F2F
red darken-3
#C62828
red darken-4
#B71C1C
red accent-1
#FF8A80
red accent-2
#FF5252
red accent-3
#FF1744
red accent-4
#D50000
pink
pink
#E91E63
pink lighten-5
#FCE4EC
pink lighten-4
#F8BBD0
pink lighten-3
#F48FB1
pink lighten-2
#F06292
pink lighten-1
#EC407A
pink darken-1
#D81B60
pink darken-2
#C2185B
pink darken-3
#AD1457
pink darken-4
#880E4F
pink accent-1
#FF80AB
pink accent-2
#FF4081
pink accent-3
#F50057
pink accent-4
#C51162
purple
purple
#9C27B0
purple lighten-5
#F3E5F5
purple lighten-4
#E1BEE7
purple lighten-3
#CE93D8
purple lighten-2
#BA68C8
purple lighten-1
#AB47BC
purple darken-1
#8E24AA
purple darken-2
#7B1FA2
purple darken-3
#6A1B9A
purple darken-4
#4A148C
purple accent-1
#EA80FC
purple accent-2
#E040FB
purple accent-3
#D500F9
purple accent-4
#AA00FF
deep-purple
deep-purple
#673AB7
deep-purple lighten-5
#EDE7F6
deep-purple lighten-4
#D1C4E9
deep-purple lighten-3
#B39DDB
deep-purple lighten-2
#9575CD
deep-purple lighten-1
#7E57C2
deep-purple darken-1
#5E35B1
deep-purple darken-2
#512DA8
deep-purple darken-3
#4527A0
deep-purple darken-4
#311B92
deep-purple accent-1
#B388FF
deep-purple accent-2
#7C4DFF
deep-purple accent-3
#651FFF
deep-purple accent-4
#6200EA
indigo
indigo
#3F51B5
indigo lighten-5
#E8EAF6
indigo lighten-4
#C5CAE9
indigo lighten-3
#9FA8DA
indigo lighten-2
#7986CB
indigo lighten-1
#5C6BC0
indigo darken-1
#3949AB
indigo darken-2
#303F9F
indigo darken-3
#283593
indigo darken-4
#1A237E
indigo accent-1
#8C9EFF
indigo accent-2
#536DFE
indigo accent-3
#3D5AFE
indigo accent-4
#304FFE
blue
blue
#2196F3
blue lighten-5
#E3F2FD
blue lighten-4
#BBDEFB
blue lighten-3
#90CAF9
blue lighten-2
#64B5F6
blue lighten-1
#42A5F5
blue darken-1
#1E88E5
blue darken-2
#1976D2
blue darken-3
#1565C0
blue darken-4
#0D47A1
blue accent-1
#82B1FF
blue accent-2
#448AFF
blue accent-3
#2979FF
blue accent-4
#2962FF
light-blue
light-blue
#03A9F4
light-blue lighten-5
#E1F5FE
light-blue lighten-4
#B3E5FC
light-blue lighten-3
#81D4FA
light-blue lighten-2
#4FC3F7
light-blue lighten-1
#29B6F6
light-blue darken-1
#039BE5
light-blue darken-2
#0288D1
light-blue darken-3
#0277BD
light-blue darken-4
#01579B
light-blue accent-1
#80D8FF
light-blue accent-2
#40C4FF
light-blue accent-3
#00B0FF
light-blue accent-4
#0091EA
cyan
cyan
#00BCD4
cyan lighten-5
#E0F7FA
cyan lighten-4
#B2EBF2
cyan lighten-3
#80DEEA
cyan lighten-2
#4DD0E1
cyan lighten-1
#26C6DA
cyan darken-1
#00ACC1
cyan darken-2
#0097A7
cyan darken-3
#00838F
cyan darken-4
#006064
cyan accent-1
#84FFFF
cyan accent-2
#18FFFF
cyan accent-3
#00E5FF
cyan accent-4
#00B8D4
teal
teal
#009688
teal lighten-5
#E0F2F1
teal lighten-4
#B2DFDB
teal lighten-3
#80CBC4
teal lighten-2
#4DB6AC
teal lighten-1
#26A69A
teal darken-1
#00897B
teal darken-2
#00796B
teal darken-3
#00695C
teal darken-4
#004D40
teal accent-1
#A7FFEB
teal accent-2
#64FFDA
teal accent-3
#1DE9B6
teal accent-4
#00BFA5
green
green
#4CAF50
green lighten-5
#E8F5E9
green lighten-4
#C8E6C9
green lighten-3
#A5D6A7
green lighten-2
#81C784
green lighten-1
#66BB6A
green darken-1
#43A047
green darken-2
#388E3C
green darken-3
#2E7D32
green darken-4
#1B5E20
green accent-1
#B9F6CA
green accent-2
#69F0AE
green accent-3
#00E676
green accent-4
#00C853
light-green
light-green
#8BC34A
light-green lighten-5
#F1F8E9
light-green lighten-4
#DCEDC8
light-green lighten-3
#C5E1A5
light-green lighten-2
#AED581
light-green lighten-1
#9CCC65
light-green darken-1
#7CB342
light-green darken-2
#689F38
light-green darken-3
#558B2F
light-green darken-4
#33691E
light-green accent-1
#CCFF90
light-green accent-2
#B2FF59
light-green accent-3
#76FF03
light-green accent-4
#64DD17
lime
lime
#CDDC39
lime lighten-5
#F9FBE7
lime lighten-4
#F0F4C3
lime lighten-3
#E6EE9C
lime lighten-2
#DCE775
lime lighten-1
#D4E157
lime darken-1
#C0CA33
lime darken-2
#AFB42B
lime darken-3
#9E9D24
lime darken-4
#827717
lime accent-1
#F4FF81
lime accent-2
#EEFF41
lime accent-3
#C6FF00
lime accent-4
#AEEA00
yellow
yellow
#FFEB3B
yellow lighten-5
#FFFDE7
yellow lighten-4
#FFF9C4
yellow lighten-3
#FFF59D
yellow lighten-2
#FFF176
yellow lighten-1
#FFEE58
yellow darken-1
#FDD835
yellow darken-2
#FBC02D
yellow darken-3
#F9A825
yellow darken-4
#F57F17
yellow accent-1
#FFFF8D
yellow accent-2
#FFFF00
yellow accent-3
#FFEA00
yellow accent-4
#FFD600
amber
amber
#FFC107
amber lighten-5
#FFF8E1
amber lighten-4
#FFECB3
amber lighten-3
#FFE082
amber lighten-2
#FFD54F
amber lighten-1
#FFCA28
amber darken-1
#FFB300
amber darken-2
#FFA000
amber darken-3
#FF8F00
amber darken-4
#FF6F00
amber accent-1
#FFE57F
amber accent-2
#FFD740
amber accent-3
#FFC400
amber accent-4
#FFAB00
orange
orange
#FF9800
orange lighten-5
#FFF3E0
orange lighten-4
#FFE0B2
orange lighten-3
#FFCC80
orange lighten-2
#FFB74D
orange lighten-1
#FFA726
orange darken-1
#FB8C00
orange darken-2
#F57C00
orange darken-3
#EF6C00
orange darken-4
#E65100
orange accent-1
#FFD180
orange accent-2
#FFAB40
orange accent-3
#FF9100
orange accent-4
#FF6D00
deep-orange
deep-orange
#FF5722
deep-orange lighten-5
#FBE9E7
deep-orange lighten-4
#FFCCBC
deep-orange lighten-3
#FFAB91
deep-orange lighten-2
#FF8A65
deep-orange lighten-1
#FF7043
deep-orange darken-1
#F4511E
deep-orange darken-2
#E64A19
deep-orange darken-3
#D84315
deep-orange darken-4
#BF360C
deep-orange accent-1
#FF9E80
deep-orange accent-2
#FF6E40
deep-orange accent-3
#FF3D00
deep-orange accent-4
#DD2C00
brown
brown
#795548
brown lighten-5
#EFEBE9
brown lighten-4
#D7CCC8
brown lighten-3
#BCAAA4
brown lighten-2
#A1887F
brown lighten-1
#8D6E63
brown darken-1
#6D4C41
brown darken-2
#5D4037
brown darken-3
#4E342E
brown darken-4
#3E2723
blue-grey
blue-grey
#607D8B
blue-grey lighten-5
#ECEFF1
blue-grey lighten-4
#CFD8DC
blue-grey lighten-3
#B0BEC5
blue-grey lighten-2
#90A4AE
blue-grey lighten-1
#78909C
blue-grey darken-1
#546E7A
blue-grey darken-2
#455A64
blue-grey darken-3
#37474F
blue-grey darken-4
#263238
grey
grey
#9E9E9E
grey lighten-5
#FAFAFA
grey lighten-4
#F5F5F5
grey lighten-3
#EEEEEE
grey lighten-2
#E0E0E0
grey lighten-1
#BDBDBD
grey darken-1
#757575
grey darken-2
#616161
grey darken-3
#424242
grey darken-4
#212121
shades
black
#000000
white
#FFFFFF
`

// const dealColor = () => {
//     let list = raw.split('\n'),
//         colorDictList = []
//     list.forEach((li, i) => {
//         if (i < list.length - 2 && li.charAt(0) !== '#' && list[i + 1].charAt(0) === '#') colorDictList.push({
//             name: li,
//             code: list[i + 1]
//         })
//     })
//     console.log(JSON.stringify(colorDictList))
// }

module.exports = [
    {name: '桂红', code: '#f25a47'},
    {"name": "red", "code": "#F44336"}, {
    "name": "red lighten-5",
    "code": "#FFEBEE"
}, {"name": "red lighten-4", "code": "#FFCDD2"}, {"name": "red lighten-3", "code": "#EF9A9A"}, {
    "name": "red lighten-2",
    "code": "#E57373"
}, {"name": "red lighten-1", "code": "#EF5350"}, {"name": "red darken-1", "code": "#E53935"}, {
    "name": "red darken-2",
    "code": "#D32F2F"
}, {"name": "red darken-3", "code": "#C62828"}, {"name": "red darken-4", "code": "#B71C1C"}, {
    "name": "red accent-1",
    "code": "#FF8A80"
}, {"name": "red accent-2", "code": "#FF5252"}, {"name": "red accent-3", "code": "#FF1744"}, {
    "name": "red accent-4",
    "code": "#D50000"
}, {"name": "pink", "code": "#E91E63"}, {"name": "pink lighten-5", "code": "#FCE4EC"}, {
    "name": "pink lighten-4",
    "code": "#F8BBD0"
}, {"name": "pink lighten-3", "code": "#F48FB1"}, {
    "name": "pink lighten-2",
    "code": "#F06292"
}, {"name": "pink lighten-1", "code": "#EC407A"}, {
    "name": "pink darken-1",
    "code": "#D81B60"
}, {"name": "pink darken-2", "code": "#C2185B"}, {"name": "pink darken-3", "code": "#AD1457"}, {
    "name": "pink darken-4",
    "code": "#880E4F"
}, {"name": "pink accent-1", "code": "#FF80AB"}, {"name": "pink accent-2", "code": "#FF4081"}, {
    "name": "pink accent-3",
    "code": "#F50057"
}, {"name": "pink accent-4", "code": "#C51162"}, {"name": "purple", "code": "#9C27B0"}, {
    "name": "purple lighten-5",
    "code": "#F3E5F5"
}, {"name": "purple lighten-4", "code": "#E1BEE7"}, {
    "name": "purple lighten-3",
    "code": "#CE93D8"
}, {"name": "purple lighten-2", "code": "#BA68C8"}, {
    "name": "purple lighten-1",
    "code": "#AB47BC"
}, {"name": "purple darken-1", "code": "#8E24AA"}, {
    "name": "purple darken-2",
    "code": "#7B1FA2"
}, {"name": "purple darken-3", "code": "#6A1B9A"}, {
    "name": "purple darken-4",
    "code": "#4A148C"
}, {"name": "purple accent-1", "code": "#EA80FC"}, {
    "name": "purple accent-2",
    "code": "#E040FB"
}, {"name": "purple accent-3", "code": "#D500F9"}, {
    "name": "purple accent-4",
    "code": "#AA00FF"
}, {"name": "deep-purple", "code": "#673AB7"}, {
    "name": "deep-purple lighten-5",
    "code": "#EDE7F6"
}, {"name": "deep-purple lighten-4", "code": "#D1C4E9"}, {
    "name": "deep-purple lighten-3",
    "code": "#B39DDB"
}, {"name": "deep-purple lighten-2", "code": "#9575CD"}, {
    "name": "deep-purple lighten-1",
    "code": "#7E57C2"
}, {"name": "deep-purple darken-1", "code": "#5E35B1"}, {
    "name": "deep-purple darken-2",
    "code": "#512DA8"
}, {"name": "deep-purple darken-3", "code": "#4527A0"}, {
    "name": "deep-purple darken-4",
    "code": "#311B92"
}, {"name": "deep-purple accent-1", "code": "#B388FF"}, {
    "name": "deep-purple accent-2",
    "code": "#7C4DFF"
}, {"name": "deep-purple accent-3", "code": "#651FFF"}, {
    "name": "deep-purple accent-4",
    "code": "#6200EA"
}, {"name": "indigo", "code": "#3F51B5"}, {"name": "indigo lighten-5", "code": "#E8EAF6"}, {
    "name": "indigo lighten-4",
    "code": "#C5CAE9"
}, {"name": "indigo lighten-3", "code": "#9FA8DA"}, {
    "name": "indigo lighten-2",
    "code": "#7986CB"
}, {"name": "indigo lighten-1", "code": "#5C6BC0"}, {
    "name": "indigo darken-1",
    "code": "#3949AB"
}, {"name": "indigo darken-2", "code": "#303F9F"}, {
    "name": "indigo darken-3",
    "code": "#283593"
}, {"name": "indigo darken-4", "code": "#1A237E"}, {
    "name": "indigo accent-1",
    "code": "#8C9EFF"
}, {"name": "indigo accent-2", "code": "#536DFE"}, {
    "name": "indigo accent-3",
    "code": "#3D5AFE"
}, {"name": "indigo accent-4", "code": "#304FFE"}, {"name": "blue", "code": "#2196F3"}, {
    "name": "blue lighten-5",
    "code": "#E3F2FD"
}, {"name": "blue lighten-4", "code": "#BBDEFB"}, {
    "name": "blue lighten-3",
    "code": "#90CAF9"
}, {"name": "blue lighten-2", "code": "#64B5F6"}, {
    "name": "blue lighten-1",
    "code": "#42A5F5"
}, {"name": "blue darken-1", "code": "#1E88E5"}, {"name": "blue darken-2", "code": "#1976D2"}, {
    "name": "blue darken-3",
    "code": "#1565C0"
}, {"name": "blue darken-4", "code": "#0D47A1"}, {"name": "blue accent-1", "code": "#82B1FF"}, {
    "name": "blue accent-2",
    "code": "#448AFF"
}, {"name": "blue accent-3", "code": "#2979FF"}, {"name": "blue accent-4", "code": "#2962FF"}, {
    "name": "light-blue",
    "code": "#03A9F4"
}, {"name": "light-blue lighten-5", "code": "#E1F5FE"}, {
    "name": "light-blue lighten-4",
    "code": "#B3E5FC"
}, {"name": "light-blue lighten-3", "code": "#81D4FA"}, {
    "name": "light-blue lighten-2",
    "code": "#4FC3F7"
}, {"name": "light-blue lighten-1", "code": "#29B6F6"}, {
    "name": "light-blue darken-1",
    "code": "#039BE5"
}, {"name": "light-blue darken-2", "code": "#0288D1"}, {
    "name": "light-blue darken-3",
    "code": "#0277BD"
}, {"name": "light-blue darken-4", "code": "#01579B"}, {
    "name": "light-blue accent-1",
    "code": "#80D8FF"
}, {"name": "light-blue accent-2", "code": "#40C4FF"}, {
    "name": "light-blue accent-3",
    "code": "#00B0FF"
}, {"name": "light-blue accent-4", "code": "#0091EA"}, {"name": "cyan", "code": "#00BCD4"}, {
    "name": "cyan lighten-5",
    "code": "#E0F7FA"
}, {"name": "cyan lighten-4", "code": "#B2EBF2"}, {
    "name": "cyan lighten-3",
    "code": "#80DEEA"
}, {"name": "cyan lighten-2", "code": "#4DD0E1"}, {
    "name": "cyan lighten-1",
    "code": "#26C6DA"
}, {"name": "cyan darken-1", "code": "#00ACC1"}, {"name": "cyan darken-2", "code": "#0097A7"}, {
    "name": "cyan darken-3",
    "code": "#00838F"
}, {"name": "cyan darken-4", "code": "#006064"}, {"name": "cyan accent-1", "code": "#84FFFF"}, {
    "name": "cyan accent-2",
    "code": "#18FFFF"
}, {"name": "cyan accent-3", "code": "#00E5FF"}, {"name": "cyan accent-4", "code": "#00B8D4"}, {
    "name": "teal",
    "code": "#009688"
}, {"name": "teal lighten-5", "code": "#E0F2F1"}, {
    "name": "teal lighten-4",
    "code": "#B2DFDB"
}, {"name": "teal lighten-3", "code": "#80CBC4"}, {
    "name": "teal lighten-2",
    "code": "#4DB6AC"
}, {"name": "teal lighten-1", "code": "#26A69A"}, {
    "name": "teal darken-1",
    "code": "#00897B"
}, {"name": "teal darken-2", "code": "#00796B"}, {"name": "teal darken-3", "code": "#00695C"}, {
    "name": "teal darken-4",
    "code": "#004D40"
}, {"name": "teal accent-1", "code": "#A7FFEB"}, {"name": "teal accent-2", "code": "#64FFDA"}, {
    "name": "teal accent-3",
    "code": "#1DE9B6"
}, {"name": "teal accent-4", "code": "#00BFA5"}, {"name": "green", "code": "#4CAF50"}, {
    "name": "green lighten-5",
    "code": "#E8F5E9"
}, {"name": "green lighten-4", "code": "#C8E6C9"}, {
    "name": "green lighten-3",
    "code": "#A5D6A7"
}, {"name": "green lighten-2", "code": "#81C784"}, {
    "name": "green lighten-1",
    "code": "#66BB6A"
}, {"name": "green darken-1", "code": "#43A047"}, {
    "name": "green darken-2",
    "code": "#388E3C"
}, {"name": "green darken-3", "code": "#2E7D32"}, {
    "name": "green darken-4",
    "code": "#1B5E20"
}, {"name": "green accent-1", "code": "#B9F6CA"}, {
    "name": "green accent-2",
    "code": "#69F0AE"
}, {"name": "green accent-3", "code": "#00E676"}, {"name": "green accent-4", "code": "#00C853"}, {
    "name": "light-green",
    "code": "#8BC34A"
}, {"name": "light-green lighten-5", "code": "#F1F8E9"}, {
    "name": "light-green lighten-4",
    "code": "#DCEDC8"
}, {"name": "light-green lighten-3", "code": "#C5E1A5"}, {
    "name": "light-green lighten-2",
    "code": "#AED581"
}, {"name": "light-green lighten-1", "code": "#9CCC65"}, {
    "name": "light-green darken-1",
    "code": "#7CB342"
}, {"name": "light-green darken-2", "code": "#689F38"}, {
    "name": "light-green darken-3",
    "code": "#558B2F"
}, {"name": "light-green darken-4", "code": "#33691E"}, {
    "name": "light-green accent-1",
    "code": "#CCFF90"
}, {"name": "light-green accent-2", "code": "#B2FF59"}, {
    "name": "light-green accent-3",
    "code": "#76FF03"
}, {"name": "light-green accent-4", "code": "#64DD17"}, {"name": "lime", "code": "#CDDC39"}, {
    "name": "lime lighten-5",
    "code": "#F9FBE7"
}, {"name": "lime lighten-4", "code": "#F0F4C3"}, {
    "name": "lime lighten-3",
    "code": "#E6EE9C"
}, {"name": "lime lighten-2", "code": "#DCE775"}, {
    "name": "lime lighten-1",
    "code": "#D4E157"
}, {"name": "lime darken-1", "code": "#C0CA33"}, {"name": "lime darken-2", "code": "#AFB42B"}, {
    "name": "lime darken-3",
    "code": "#9E9D24"
}, {"name": "lime darken-4", "code": "#827717"}, {"name": "lime accent-1", "code": "#F4FF81"}, {
    "name": "lime accent-2",
    "code": "#EEFF41"
}, {"name": "lime accent-3", "code": "#C6FF00"}, {"name": "lime accent-4", "code": "#AEEA00"}, {
    "name": "yellow",
    "code": "#FFEB3B"
}, {"name": "yellow lighten-5", "code": "#FFFDE7"}, {
    "name": "yellow lighten-4",
    "code": "#FFF9C4"
}, {"name": "yellow lighten-3", "code": "#FFF59D"}, {
    "name": "yellow lighten-2",
    "code": "#FFF176"
}, {"name": "yellow lighten-1", "code": "#FFEE58"}, {
    "name": "yellow darken-1",
    "code": "#FDD835"
}, {"name": "yellow darken-2", "code": "#FBC02D"}, {
    "name": "yellow darken-3",
    "code": "#F9A825"
}, {"name": "yellow darken-4", "code": "#F57F17"}, {
    "name": "yellow accent-1",
    "code": "#FFFF8D"
}, {"name": "yellow accent-2", "code": "#FFFF00"}, {
    "name": "yellow accent-3",
    "code": "#FFEA00"
}, {"name": "yellow accent-4", "code": "#FFD600"}, {"name": "amber", "code": "#FFC107"}, {
    "name": "amber lighten-5",
    "code": "#FFF8E1"
}, {"name": "amber lighten-4", "code": "#FFECB3"}, {
    "name": "amber lighten-3",
    "code": "#FFE082"
}, {"name": "amber lighten-2", "code": "#FFD54F"}, {
    "name": "amber lighten-1",
    "code": "#FFCA28"
}, {"name": "amber darken-1", "code": "#FFB300"}, {
    "name": "amber darken-2",
    "code": "#FFA000"
}, {"name": "amber darken-3", "code": "#FF8F00"}, {
    "name": "amber darken-4",
    "code": "#FF6F00"
}, {"name": "amber accent-1", "code": "#FFE57F"}, {
    "name": "amber accent-2",
    "code": "#FFD740"
}, {"name": "amber accent-3", "code": "#FFC400"}, {"name": "amber accent-4", "code": "#FFAB00"}, {
    "name": "orange",
    "code": "#FF9800"
}, {"name": "orange lighten-5", "code": "#FFF3E0"}, {
    "name": "orange lighten-4",
    "code": "#FFE0B2"
}, {"name": "orange lighten-3", "code": "#FFCC80"}, {
    "name": "orange lighten-2",
    "code": "#FFB74D"
}, {"name": "orange lighten-1", "code": "#FFA726"}, {
    "name": "orange darken-1",
    "code": "#FB8C00"
}, {"name": "orange darken-2", "code": "#F57C00"}, {
    "name": "orange darken-3",
    "code": "#EF6C00"
}, {"name": "orange darken-4", "code": "#E65100"}, {
    "name": "orange accent-1",
    "code": "#FFD180"
}, {"name": "orange accent-2", "code": "#FFAB40"}, {
    "name": "orange accent-3",
    "code": "#FF9100"
}, {"name": "orange accent-4", "code": "#FF6D00"}, {
    "name": "deep-orange",
    "code": "#FF5722"
}, {"name": "deep-orange lighten-5", "code": "#FBE9E7"}, {
    "name": "deep-orange lighten-4",
    "code": "#FFCCBC"
}, {"name": "deep-orange lighten-3", "code": "#FFAB91"}, {
    "name": "deep-orange lighten-2",
    "code": "#FF8A65"
}, {"name": "deep-orange lighten-1", "code": "#FF7043"}, {
    "name": "deep-orange darken-1",
    "code": "#F4511E"
}, {"name": "deep-orange darken-2", "code": "#E64A19"}, {
    "name": "deep-orange darken-3",
    "code": "#D84315"
}, {"name": "deep-orange darken-4", "code": "#BF360C"}, {
    "name": "deep-orange accent-1",
    "code": "#FF9E80"
}, {"name": "deep-orange accent-2", "code": "#FF6E40"}, {
    "name": "deep-orange accent-3",
    "code": "#FF3D00"
}, {"name": "deep-orange accent-4", "code": "#DD2C00"}, {
    "name": "brown",
    "code": "#795548"
}, {"name": "brown lighten-5", "code": "#EFEBE9"}, {
    "name": "brown lighten-4",
    "code": "#D7CCC8"
}, {"name": "brown lighten-3", "code": "#BCAAA4"}, {
    "name": "brown lighten-2",
    "code": "#A1887F"
}, {"name": "brown lighten-1", "code": "#8D6E63"}, {
    "name": "brown darken-1",
    "code": "#6D4C41"
}, {"name": "brown darken-2", "code": "#5D4037"}, {
    "name": "brown darken-3",
    "code": "#4E342E"
}, {"name": "brown darken-4", "code": "#3E2723"}, {
    "name": "blue-grey",
    "code": "#607D8B"
}, {"name": "blue-grey lighten-5", "code": "#ECEFF1"}, {
    "name": "blue-grey lighten-4",
    "code": "#CFD8DC"
}, {"name": "blue-grey lighten-3", "code": "#B0BEC5"}, {
    "name": "blue-grey lighten-2",
    "code": "#90A4AE"
}, {"name": "blue-grey lighten-1", "code": "#78909C"}, {
    "name": "blue-grey darken-1",
    "code": "#546E7A"
}, {"name": "blue-grey darken-2", "code": "#455A64"}, {
    "name": "blue-grey darken-3",
    "code": "#37474F"
}, {"name": "blue-grey darken-4", "code": "#263238"}, {"name": "grey", "code": "#9E9E9E"}, {
    "name": "grey lighten-5",
    "code": "#FAFAFA"
}, {"name": "grey lighten-4", "code": "#F5F5F5"}, {
    "name": "grey lighten-3",
    "code": "#EEEEEE"
}, {"name": "grey lighten-2", "code": "#E0E0E0"}, {
    "name": "grey lighten-1",
    "code": "#BDBDBD"
}, {"name": "grey darken-1", "code": "#757575"}, {"name": "grey darken-2", "code": "#616161"}, {
    "name": "grey darken-3",
    "code": "#424242"
}, {"name": "grey darken-4", "code": "#212121"}, {"name": "black", "code": "#000000"}, {
    "name": "white",
    "code": "#FFFFFF"
}]


import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/inquiry/:shortUrl',
    name: 'Inquiry',
    meta: {
      title: '健康问询'
    },
    component: () => import(/* webpackChunkName: "inquiry" */ '../views/Inquiry.vue')
  },
  {
    path: '/report/:shortUrl',
    name: 'Report',
    meta: {
      title: '健康档案'
    },
    component: () => import(/* webpackChunkName: "report" */ '../views/Report.vue')
  },
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '云辨健康'
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/plan',
    name: 'Plan',
    meta: {
      title: '健康方案'
    },
    component: () => import(/* webpackChunkName: "plan" */ '../views/Plan.vue')
  },
  {
    path: '/visualization',
    name: 'Visualization',
    meta: {
      title: '云辨健康数据大屏'
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Visualization.vue')
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: {
      title: '404'
    },
    component: () => import(/* webpackChunkName: "404" */ '../views/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

export default [
    {
        id: '1e7f604b86415ade94e15fef8627609b',
        name: 'scene',
        indexes: []
    },
    {
        id: '4f0b2d935c1a85b336bdf7635cb432cb',
        name: '进入问询首页',
        indexes: [
            {
                index: 1,
                default: {
                    id: 'gRYzt2_vJd',
                    scene: '进入问询首页',
                    condition: '',
                    default: true,
                    name: '介绍整体流程',
                    content: '以后您的健康就由我们来守护了~',
                    index: 1,
                    level: 1,
                    labels: ['介绍', '寒暄'],
                    endOfScene: false,
                    sceneId: '4f0b2d935c1a85b336bdf7635cb432cb'
                },
                items: [
                    {
                        id: 'gRYzt2_vJd',
                        scene: '进入问询首页',
                        condition: '',
                        default: true,
                        name: '介绍整体流程',
                        content: '以后您的健康就由我们来守护了~',
                        index: 1,
                        level: 1,
                        labels: ['介绍', '寒暄'],
                        endOfScene: false,
                        sceneId: '4f0b2d935c1a85b336bdf7635cb432cb'
                    }
                ]
            },
            {
                index: 2,
                default: {
                    id: 'tvItb1DGxW',
                    scene: '进入问询首页',
                    condition: '等待人多',
                    default: true,
                    name: '转其他专员',
                    content: '请稍等，我给您联系其他专员',
                    index: 2,
                    level: 1,
                    labels: ['引导'],
                    endOfScene: false,
                    sceneId: '4f0b2d935c1a85b336bdf7635cb432cb'
                },
                items: [
                    {
                        id: 'WrL13RjysG',
                        scene: '进入问询首页',
                        condition: '等待人多',
                        default: true,
                        name: '引导分流',
                        content: '现在人有点多，您可以先扫码填一下基础信息，测一下体质',
                        index: 2,
                        level: 1,
                        labels: ['引导'],
                        action: 'showConsCode',
                        endOfScene: false,
                        sceneId: '4f0b2d935c1a85b336bdf7635cb432cb'
                    },
                    {
                        id: 'tvItb1DGxW',
                        scene: '进入问询首页',
                        condition: '等待人多',
                        default: true,
                        name: '转其他专员',
                        content: '请稍等，我给您联系其他专员',
                        index: 2,
                        level: 1,
                        labels: ['引导'],
                        endOfScene: false,
                        sceneId: '4f0b2d935c1a85b336bdf7635cb432cb'
                    }
                ]
            },
            {
                index: 3,
                default: {
                    id: '4Gy11Oww64',
                    scene: '进入问询首页',
                    condition: '引导结束',
                    default: true,
                    name: '开始建档',
                    content: '接下来给开始给您建立健康档案',
                    index: 3,
                    level: 1,
                    labels: [],
                    endOfScene: true,
                    sceneId: '4f0b2d935c1a85b336bdf7635cb432cb'
                },
                items: [
                    {
                        id: '4Gy11Oww64',
                        scene: '进入问询首页',
                        condition: '引导结束',
                        default: true,
                        name: '开始建档',
                        content: '接下来给开始给您建立健康档案',
                        index: 3,
                        level: 1,
                        labels: [],
                        endOfScene: true,
                        sceneId: '4f0b2d935c1a85b336bdf7635cb432cb'
                    }
                ]
            }
        ]
    },
    {
        id: '82ec26b65698fca787de179609d05292',
        name: '初始症状问询',
        indexes: [
            {
                index: 1,
                default: {
                    id: 'fsbXVppeqG',
                    scene: '初始症状问询',
                    condition: '',
                    default: true,
                    name: '询问躯体症状',
                    content: '您四肢关节有不舒服么?',
                    index: 1,
                    level: 1,
                    labels: ['四肢，关节'],
                    action: 'showBodySymptoms',
                    endOfScene: false,
                    sceneId: '82ec26b65698fca787de179609d05292'
                },
                items: [
                    {
                        id: '4_gGtjnIGS',
                        scene: '初始症状问询',
                        condition: '',
                        default: true,
                        name: '询问躯体症状',
                        content: '您现在整体感觉怎么样，有哪儿不舒服么?',
                        index: 1,
                        level: 1,
                        labels: ['问询'],
                        endOfScene: false,
                        sceneId: '82ec26b65698fca787de179609d05292'
                    },
                    {
                        id: 'liZdqbgl8q',
                        scene: '初始症状问询',
                        condition: '',
                        default: true,
                        name: '询问躯体症状',
                        content: '您头面部有不舒服么?',
                        index: 1,
                        level: 1,
                        labels: ['头面'],
                        action: 'showBodySymptoms',
                        endOfScene: false,
                        sceneId: '82ec26b65698fca787de179609d05292'
                    },
                    {
                        id: 'EIg7bOhDld',
                        scene: '初始症状问询',
                        condition: '',
                        default: true,
                        name: '询问躯体症状',
                        content: '您颈胸腰背部有不舒服么?',
                        index: 1,
                        level: 1,
                        labels: ['颈胸腰背'],
                        action: 'showBodySymptoms',
                        endOfScene: false,
                        sceneId: '82ec26b65698fca787de179609d05292'
                    },
                    {
                        id: 'fsbXVppeqG',
                        scene: '初始症状问询',
                        condition: '',
                        default: true,
                        name: '询问躯体症状',
                        content: '您四肢关节有不舒服么?',
                        index: 1,
                        level: 1,
                        labels: ['四肢，关节'],
                        action: 'showBodySymptoms',
                        endOfScene: false,
                        sceneId: '82ec26b65698fca787de179609d05292'
                    }
                ]
            },
            {
                index: 2,
                default: {
                    id: 'd0GYxbeRwV',
                    scene: '初始症状问询',
                    condition: '',
                    default: true,
                    name: '询问生命活动类症状',
                    content: '您大便怎么样，每天几次，成型么?',
                    index: 2,
                    level: 1,
                    labels: ['生命活动问询'],
                    action: 'showLifeActionSymptoms',
                    endOfScene: false,
                    sceneId: '82ec26b65698fca787de179609d05292'
                },
                items: [
                    {
                        id: 'YRdAkDDwlX',
                        scene: '初始症状问询',
                        condition: '',
                        default: true,
                        name: '询问生命活动类症状',
                        content: '您吃饭睡觉，整体感觉怎么样?',
                        index: 2,
                        level: 1,
                        labels: ['生命活动问询'],
                        action: 'showLifeActionSymptoms',
                        endOfScene: false,
                        sceneId: '82ec26b65698fca787de179609d05292'
                    },
                    {
                        id: 'S-JBLveq47',
                        scene: '初始症状问询',
                        condition: '',
                        default: true,
                        name: '询问生命活动类症状',
                        content: '您睡眠好么，有失眠多梦，入睡难容易醒么?',
                        index: 2,
                        level: 1,
                        labels: ['生命活动问询'],
                        action: 'showLifeActionSymptoms',
                        endOfScene: false,
                        sceneId: '82ec26b65698fca787de179609d05292'
                    },
                    {
                        id: 'rVd97jw-MZ',
                        scene: '初始症状问询',
                        condition: '',
                        default: true,
                        name: '询问生命活动类症状',
                        content: '您胃口怎么样?',
                        index: 2,
                        level: 1,
                        labels: ['生命活动问询'],
                        action: 'showLifeActionSymptoms',
                        endOfScene: false,
                        sceneId: '82ec26b65698fca787de179609d05292'
                    },
                    {
                        id: 'd0GYxbeRwV',
                        scene: '初始症状问询',
                        condition: '',
                        default: true,
                        name: '询问生命活动类症状',
                        content: '您大便怎么样，每天几次，成型么?',
                        index: 2,
                        level: 1,
                        labels: ['生命活动问询'],
                        action: 'showLifeActionSymptoms',
                        endOfScene: false,
                        sceneId: '82ec26b65698fca787de179609d05292'
                    }
                ]
            },
            {
                index: 3,
                default: {
                    id: 'lFniG6kpFm',
                    scene: '初始症状问询',
                    condition: '初始症状完成',
                    default: true,
                    name: '选择主诉',
                    content: '这些问题您觉得那个最严重，最想解决?',
                    index: 3,
                    level: 1,
                    labels: ['问询', '主诉'],
                    action: 'selectChiefComplaint',
                    endOfScene: true,
                    sceneId: '82ec26b65698fca787de179609d05292'
                },
                items: [
                    {
                        id: 'lFniG6kpFm',
                        scene: '初始症状问询',
                        condition: '初始症状完成',
                        default: true,
                        name: '选择主诉',
                        content: '这些问题您觉得那个最严重，最想解决?',
                        index: 3,
                        level: 1,
                        labels: ['问询', '主诉'],
                        action: 'selectChiefComplaint',
                        endOfScene: true,
                        sceneId: '82ec26b65698fca787de179609d05292'
                    }
                ]
            }
        ]
    },
    {
        id: 'd78c2c435e91aa95ce46640d44ce813b',
        name: '选择主诉问题',
        indexes: [
            {
                index: 1,
                default: {
                    id: 'iNYwnpFrr5',
                    scene: '选择主诉问题',
                    condition: '主诉选择完成',
                    default: true,
                    name: '确定初始症状程度',
                    content: '**程度，比如**?',
                    index: 1,
                    level: 1,
                    labels: ['问询'],
                    endOfScene: false,
                    sceneId: 'd78c2c435e91aa95ce46640d44ce813b'
                },
                items: [
                    {
                        id: 'iNYwnpFrr5',
                        scene: '选择主诉问题',
                        condition: '主诉选择完成',
                        default: true,
                        name: '确定初始症状程度',
                        content: '**程度，比如**?',
                        index: 1,
                        level: 1,
                        labels: ['问询'],
                        endOfScene: false,
                        sceneId: 'd78c2c435e91aa95ce46640d44ce813b'
                    }
                ]
            },
            {
                index: 2,
                default: {
                    id: 'GfcgSwQMi8',
                    scene: '选择主诉问题',
                    condition: '初始症状程度确认',
                    default: true,
                    name: '主症量表询问',
                    content: '接下来详细给您记录一下**的情况?',
                    index: 2,
                    level: 1,
                    labels: ['过渡'],
                    endOfScene: true,
                    sceneId: 'd78c2c435e91aa95ce46640d44ce813b'
                },
                items: [
                    {
                        id: 'GfcgSwQMi8',
                        scene: '选择主诉问题',
                        condition: '初始症状程度确认',
                        default: true,
                        name: '主症量表询问',
                        content: '接下来详细给您记录一下**的情况?',
                        index: 2,
                        level: 1,
                        labels: ['过渡'],
                        endOfScene: true,
                        sceneId: 'd78c2c435e91aa95ce46640d44ce813b'
                    }
                ]
            }
        ]
    },
    {
        id: '0f571f987eaf744902f711f81153bcfb',
        name: '进行症状联想',
        indexes: [
            {
                index: 1,
                default: {
                    id: 'fIz96q3QIW',
                    scene: '进行症状联想',
                    condition: '整体问询',
                    default: true,
                    name: '症状联想询问',
                    content: '接下来给您记录一下整体的症状',
                    index: 1,
                    level: 1,
                    labels: ['联想问询'],
                    endOfScene: false,
                    sceneId: '0f571f987eaf744902f711f81153bcfb'
                },
                items: [
                    {
                        id: 'fIz96q3QIW',
                        scene: '进行症状联想',
                        condition: '整体问询',
                        default: true,
                        name: '症状联想询问',
                        content: '接下来给您记录一下整体的症状',
                        index: 1,
                        level: 1,
                        labels: ['联想问询'],
                        endOfScene: false,
                        sceneId: '0f571f987eaf744902f711f81153bcfb'
                    }
                ]
            },
            {
                index: 2,
                default: {
                    id: '9DKglXo5f1',
                    scene: '进行症状联想',
                    condition: '',
                    default: true,
                    name: '询问干扰项',
                    content: '您刚吃完火龙果这类有颜色的东西吗，我看您嘴巴特别红？',
                    index: 2,
                    level: 1,
                    labels: ['排除干扰项'],
                    endOfScene: false,
                    sceneId: '0f571f987eaf744902f711f81153bcfb'
                },
                items: [
                    {
                        id: 'v1LVE5X_Aa',
                        scene: '进行症状联想',
                        condition: '',
                        default: true,
                        name: '询问躯体症状',
                        content: '您有咽喉感觉异常么?',
                        index: 2,
                        level: 1,
                        labels: [],
                        endOfScene: false,
                        sceneId: '0f571f987eaf744902f711f81153bcfb'
                    },
                    {
                        id: '3zVS8mo9gP',
                        scene: '进行症状联想',
                        condition: '',
                        default: true,
                        name: '根据问题完善问诊',
                        content: '您有头吗?--您有头部不适吗?',
                        index: 2,
                        level: 1,
                        labels: ['联想题目不完善'],
                        endOfScene: false,
                        sceneId: '0f571f987eaf744902f711f81153bcfb'
                    },
                    {
                        id: 'dfXdmxcaps',
                        scene: '进行症状联想',
                        condition: '',
                        default: true,
                        name: '根据观察针对问询',
                        content: '我看您现在面色红，是一直这样吗?',
                        index: 2,
                        level: 1,
                        labels: ['体征题目'],
                        endOfScene: false,
                        sceneId: '0f571f987eaf744902f711f81153bcfb'
                    },
                    {
                        id: 'CJx74DHzDl',
                        scene: '进行症状联想',
                        condition: '',
                        default: true,
                        name: '观察项目',
                        content: '麻烦您伸一下舌头，我看一下舌苔.',
                        index: 2,
                        level: 1,
                        labels: ['需实际观察'],
                        endOfScene: false,
                        sceneId: '0f571f987eaf744902f711f81153bcfb'
                    },
                    {
                        id: '9DKglXo5f1',
                        scene: '进行症状联想',
                        condition: '',
                        default: true,
                        name: '询问干扰项',
                        content: '您刚吃完火龙果这类有颜色的东西吗，我看您嘴巴特别红？',
                        index: 2,
                        level: 1,
                        labels: ['排除干扰项'],
                        endOfScene: false,
                        sceneId: '0f571f987eaf744902f711f81153bcfb'
                    }
                ]
            },
            {
                index: 3,
                default: {
                    id: '6hBDbrxFiu',
                    scene: '进行症状联想',
                    condition: '连续多轮无症状',
                    default: true,
                    name: '完成症状联想',
                    content: '症状问完了，接下来给您记录一下其他情况。',
                    index: 3,
                    level: 1,
                    labels: ['过渡'],
                    endOfScene: true,
                    sceneId: '0f571f987eaf744902f711f81153bcfb'
                },
                items: [
                    {
                        id: '6hBDbrxFiu',
                        scene: '进行症状联想',
                        condition: '连续多轮无症状',
                        default: true,
                        name: '完成症状联想',
                        content: '症状问完了，接下来给您记录一下其他情况。',
                        index: 3,
                        level: 1,
                        labels: ['过渡'],
                        endOfScene: true,
                        sceneId: '0f571f987eaf744902f711f81153bcfb'
                    }
                ]
            }
        ]
    },
    {
        id: '7b886ee5e4ab507548db9b70a4c6e5d8',
        name: '进行既往史、过敏史问询',
        indexes: [
            {
                index: 1,
                default: {
                    id: 'ddlE7LIZO_',
                    scene: '进行既往史、过敏史问询',
                    condition: '',
                    default: true,
                    name: '询问既往史',
                    content: '您之前有什么疾病吗，比如高血压、糖尿病？',
                    index: 1,
                    level: 1,
                    labels: ['既往史', '问询'],
                    endOfScene: false,
                    sceneId: '7b886ee5e4ab507548db9b70a4c6e5d8'
                },
                items: [
                    {
                        id: 'ddlE7LIZO_',
                        scene: '进行既往史、过敏史问询',
                        condition: '',
                        default: true,
                        name: '询问既往史',
                        content: '您之前有什么疾病吗，比如高血压、糖尿病？',
                        index: 1,
                        level: 1,
                        labels: ['既往史', '问询'],
                        endOfScene: false,
                        sceneId: '7b886ee5e4ab507548db9b70a4c6e5d8'
                    }
                ]
            },
            {
                index: 2,
                default: {
                    id: '2bu3Z04kct',
                    scene: '进行既往史、过敏史问询',
                    condition: '',
                    default: true,
                    name: '询问过敏史',
                    content: '您之前有什么过敏吗，比如青霉素、花粉过敏？',
                    index: 2,
                    level: 1,
                    labels: ['过敏史', '问询'],
                    endOfScene: false,
                    sceneId: '7b886ee5e4ab507548db9b70a4c6e5d8'
                },
                items: [
                    {
                        id: '2bu3Z04kct',
                        scene: '进行既往史、过敏史问询',
                        condition: '',
                        default: true,
                        name: '询问过敏史',
                        content: '您之前有什么过敏吗，比如青霉素、花粉过敏？',
                        index: 2,
                        level: 1,
                        labels: ['过敏史', '问询'],
                        endOfScene: false,
                        sceneId: '7b886ee5e4ab507548db9b70a4c6e5d8'
                    }
                ]
            },
            {
                index: 3,
                default: {
                    id: '2i1vYYWkYI',
                    scene: '进行既往史、过敏史问询',
                    condition: '',
                    default: true,
                    name: '完成问询',
                    content: '基本情况都了解了，接下来给您生成一下健康档案。',
                    index: 3,
                    level: 1,
                    labels: ['过渡'],
                    endOfScene: true,
                    sceneId: '7b886ee5e4ab507548db9b70a4c6e5d8'
                },
                items: [
                    {
                        id: '2i1vYYWkYI',
                        scene: '进行既往史、过敏史问询',
                        condition: '',
                        default: true,
                        name: '完成问询',
                        content: '基本情况都了解了，接下来给您生成一下健康档案。',
                        index: 3,
                        level: 1,
                        labels: ['过渡'],
                        endOfScene: true,
                        sceneId: '7b886ee5e4ab507548db9b70a4c6e5d8'
                    }
                ]
            }
        ]
    },
    {
        id: 'cabf4904a12ceef1337ebe06ba61c3b0',
        name: '保存健康诊断',
        indexes: [
            {
                index: 1,
                default: {
                    id: 'U9a9xWERP3',
                    scene: '保存健康诊断',
                    condition: '',
                    default: true,
                    name: '选择诊断，进行诊断保存',
                    content: '',
                    index: 1,
                    level: 1,
                    labels: ['健康诊断', '保存诊断'],
                    endOfScene: true,
                    sceneId: 'cabf4904a12ceef1337ebe06ba61c3b0'
                },
                items: [
                    {
                        id: 'U9a9xWERP3',
                        scene: '保存健康诊断',
                        condition: '',
                        default: true,
                        name: '选择诊断，进行诊断保存',
                        content: '',
                        index: 1,
                        level: 1,
                        labels: ['健康诊断', '保存诊断'],
                        endOfScene: true,
                        sceneId: 'cabf4904a12ceef1337ebe06ba61c3b0'
                    }
                ]
            },
            {
                index: 2,
                default: {
                    id: 'XWq-LJygU9',
                    scene: '保存健康诊断',
                    condition: '无匹配诊断',
                    default: true,
                    name: '自定义诊断，进行保存',
                    content: '',
                    index: 2,
                    level: 1,
                    labels: ['健康诊断', '保存诊断'],
                    endOfScene: false,
                    sceneId: 'cabf4904a12ceef1337ebe06ba61c3b0'
                },
                items: [
                    {
                        id: 'XWq-LJygU9',
                        scene: '保存健康诊断',
                        condition: '无匹配诊断',
                        default: true,
                        name: '自定义诊断，进行保存',
                        content: '',
                        index: 2,
                        level: 1,
                        labels: ['健康诊断', '保存诊断'],
                        endOfScene: false,
                        sceneId: 'cabf4904a12ceef1337ebe06ba61c3b0'
                    }
                ]
            }
        ]
    },
    {
        id: 'aa9ef101934365d186ca0ede932c32cc',
        name: '健康记录解读',
        indexes: [
            {
                index: 1,
                default: {
                    id: 'tdXPMIKusV',
                    scene: '健康记录解读',
                    condition: '等待人多',
                    default: true,
                    name: '转其他专员',
                    content: '请稍等， 我给您联系其他专员进行档案解读。',
                    index: 1,
                    level: 1,
                    labels: ['引导'],
                    endOfScene: false,
                    sceneId: 'aa9ef101934365d186ca0ede932c32cc',
                },
                items: [
                    {
                        id: 'aG3qqtUSal',
                        scene: '健康记录解读',
                        condition: '等待人多',
                        default: true,
                        name: '引导分流',
                        content: '您可以先扫码测关注一下公众号，上面就可以看到您的档案了，稍后我为您详细解读。',
                        index: 1,
                        level: 1,
                        labels: ['引导'],
                        endOfScene: false,
                        sceneId: 'aa9ef101934365d186ca0ede932c32cc',
                        action: 'showConsCode'
                    },
                    {
                        id: 'tdXPMIKusV',
                        scene: '健康记录解读',
                        condition: '等待人多',
                        default: true,
                        name: '转其他专员',
                        content: '请稍等， 我给您联系其他专员进行档案解读。',
                        index: 1,
                        level: 1,
                        labels: ['引导'],
                        endOfScene: false,
                        sceneId: 'aa9ef101934365d186ca0ede932c32cc',
                        action: 'showConsCode'
                    }
                ]
            },
            {
                index: 2,
                default: {
                    id: 'xzDw7LIe6v',
                    scene: '健康记录解读',
                    condition: '',
                    default: true,
                    name: '健康档案解读',
                    content: '因为您***，所以出现***，导致***，诊断为***。',
                    index: 2,
                    level: 1,
                    labels: ['档案', '解读'],
                    endOfScene: false,
                    sceneId: 'aa9ef101934365d186ca0ede932c32cc'
                },
                items: [
                    {
                        id: 'xzDw7LIe6v',
                        scene: '健康记录解读',
                        condition: '',
                        default: true,
                        name: '健康档案解读',
                        content: '因为您***，所以出现***，导致***，诊断为***。',
                        index: 2,
                        level: 1,
                        labels: ['档案', '解读'],
                        endOfScene: false,
                        sceneId: 'aa9ef101934365d186ca0ede932c32cc',
                        action: 'showConsCode'
                    }
                ]
            },
            {
                index: 3,
                default: {
                    id: 'a-S4tIEy6-',
                    scene: '健康记录解读',
                    condition: '',
                    default: true,
                    name: '健康档案解读',
                    content: '档案您可以在公众号随时查看，接下来给您进行一个产品推荐。',
                    index: 3,
                    level: 1,
                    labels: ['档案', '解读'],
                    endOfScene: false,
                    sceneId: 'aa9ef101934365d186ca0ede932c32cc'
                },
                items: [
                    {
                        id: 'a-S4tIEy6-',
                        scene: '健康记录解读',
                        condition: '',
                        default: true,
                        name: '健康档案解读',
                        content: '档案您可以在公众号随时查看，接下来给您进行一个产品推荐。',
                        index: 3,
                        level: 1,
                        labels: ['档案', '解读'],
                        endOfScene: false,
                        sceneId: 'aa9ef101934365d186ca0ede932c32cc'
                    }
                ]
            }
        ]
    },
    {
        id: 'f402c69ce123e32c8d94ae79c376c600',
        name: '产品推荐',
        indexes: [
            {
                index: 1,
                default: {
                    id: 'lDmnDUUbsA',
                    scene: '产品推荐',
                    condition: '',
                    default: true,
                    name: '进行产品推荐',
                    content: '接下来有一些产品您平时可以用一下，针对症状进行一个调养。',
                    index: 1,
                    level: 1,
                    labels: ['档案', '解读'],
                    endOfScene: true,
                    sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                },
                items: [
                    {
                        id: 'lDmnDUUbsA',
                        scene: '产品推荐',
                        condition: '',
                        default: true,
                        name: '进行产品推荐',
                        content: '接下来有一些产品您平时可以用一下，针对症状进行一个调养。',
                        index: 1,
                        level: 1,
                        labels: ['档案', '解读'],
                        endOfScene: true,
                        sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                    }
                ]
            },
            {
                index: 2,
                default: {
                    id: 'MxuiFQEPKq',
                    scene: '产品推荐',
                    condition: '拒绝推荐',
                    default: true,
                    name: '日常沟通',
                    content: '您可以听一下，有一些是日常家里常备的，您完全可以回去利用自己家里的东西来保健。',
                    index: 2,
                    level: 1,
                    labels: ['寒暄'],
                    endOfScene: false,
                    sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                },
                items: [
                    {
                        id: 'MxuiFQEPKq',
                        scene: '产品推荐',
                        condition: '拒绝推荐',
                        default: true,
                        name: '日常沟通',
                        content: '您可以听一下，有一些是日常家里常备的，您完全可以回去利用自己家里的东西来保健。',
                        index: 2,
                        level: 1,
                        labels: ['寒暄'],
                        endOfScene: false,
                        sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                    }
                ]
            },
            {
                index: 3,
                default: {
                    id: 'eCsJgAPFNe',
                    scene: '产品推荐',
                    condition: '',
                    default: true,
                    name: '问询健康条件',
                    content: '您这次最不舒服的是什么呢？',
                    index: 3,
                    level: 1,
                    labels: ['问询', '健康条件'],
                    endOfScene: false,
                    sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                },
                items: [
                    {
                        id: 'eCsJgAPFNe',
                        scene: '产品推荐',
                        condition: '',
                        default: true,
                        name: '问询健康条件',
                        content: '您这次最不舒服的是什么呢？',
                        index: 3,
                        level: 1,
                        labels: ['问询', '健康条件'],
                        endOfScene: false,
                        sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                    }
                ]
            },
            {
                index: 4,
                default: {
                    id: 'WsXFh_nrHv',
                    scene: '产品推荐',
                    condition: '',
                    default: true,
                    name: '问询优选产品类别',
                    content: '我们这边艾灸、药膳、产品都有，您看您想选什么类型的呢？',
                    index: 4,
                    level: 1,
                    labels: ['问询', '喜好产品类型'],
                    endOfScene: false,
                    sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                },
                items: [
                    {
                        id: 'WsXFh_nrHv',
                        scene: '产品推荐',
                        condition: '',
                        default: true,
                        name: '问询优选产品类别',
                        content: '我们这边艾灸、药膳、产品都有，您看您想选什么类型的呢？',
                        index: 4,
                        level: 1,
                        labels: ['问询', '喜好产品类型'],
                        endOfScene: false,
                        sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                    }
                ]
            },
            {
                index: 5,
                default: {
                    id: 'O7CrEBSDpz',
                    scene: '产品推荐',
                    condition: '',
                    default: true,
                    name: '介绍食材',
                    content: '您有高血压，日常可以吃点黄豆芽，有降压的效果。',
                    index: 5,
                    level: 1,
                    labels: ['介绍', '食材'],
                    action: 'introduceFoods',
                    endOfScene: false,
                    sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                },
                items: [
                    {
                        id: 'Mc0HGzG_ch',
                        scene: '产品推荐',
                        condition: '',
                        default: true,
                        name: '介绍艾灸产品',
                        content: '您可以买点艾条自己来做，也可以去旁边门诊，需要我给您提前预约吗？',
                        index: 5,
                        level: 1,
                        labels: ['介绍', '艾灸产品'],
                        action: 'introduceAijiu',
                        endOfScene: false,
                        sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                    },
                    {
                        id: 'p3Cdlk-i0A',
                        scene: '产品推荐',
                        condition: '',
                        default: true,
                        name: '介绍药膳食疗',
                        content: '您***不好，可以吃点***，日常喝点***',
                        index: 5,
                        level: 1,
                        labels: ['介绍', '药膳食疗'],
                        action: 'introduceRecipies',
                        endOfScene: false,
                        sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                    },
                    {
                        id: '0cNILayMUf',
                        scene: '产品推荐',
                        condition: '',
                        default: true,
                        name: '介绍优选产品',
                        content: '嫌自己熬粥麻烦的话，我们这里有***',
                        index: 5,
                        level: 1,
                        labels: ['介绍', '优选产品'],
                        action: 'introduceProducts',
                        endOfScene: false,
                        sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                    },
                    {
                        id: 'O7CrEBSDpz',
                        scene: '产品推荐',
                        condition: '',
                        default: true,
                        name: '介绍食材',
                        content: '您有高血压，日常可以吃点黄豆芽，有降压的效果。',
                        index: 5,
                        level: 1,
                        labels: ['介绍', '食材'],
                        action: 'introduceFoods',
                        endOfScene: false,
                        sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                    }
                ]
            },
            {
                index: 6,
                default: {
                    id: '32G7K0ZAa6',
                    scene: '产品推荐',
                    condition: '存在禁忌',
                    default: true,
                    name: '记录禁忌事项',
                    content: '之前推荐的食品有您不吃的吗，您可以说一下，我给咱备注筛选一下。',
                    index: 6,
                    level: 1,
                    labels: ['禁忌记录'],
                    action: 'introduceFoodCautions',
                    endOfScene: true,
                    sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                },
                items: [
                    {
                        id: '32G7K0ZAa6',
                        scene: '产品推荐',
                        condition: '存在禁忌',
                        default: true,
                        name: '记录禁忌事项',
                        content: '之前推荐的食品有您不吃的吗，您可以说一下，我给咱备注筛选一下。',
                        index: 6,
                        level: 1,
                        labels: ['禁忌记录'],
                        action: 'introduceFoodCautions',
                        endOfScene: true,
                        sceneId: 'f402c69ce123e32c8d94ae79c376c600'
                    }
                ]
            }
        ]
    },
    {
        id: 'f399dcc10cc256d383126c2dd37d103f',
        name: '用药提醒',
        indexes: [
            {
                index: 1,
                default: {
                    id: 'f2elPY9jCu',
                    scene: '用药提醒',
                    condition: '',
                    default: true,
                    name: '询问服药',
                    content: '您平时有吃药吗，咱们这边也可以做一个用药提醒，以后就不担心忘记吃药了。',
                    index: 1,
                    level: 1,
                    labels: ['用药'],
                    action: 'setMedicineReminder',
                    endOfScene: false,
                    sceneId: 'f399dcc10cc256d383126c2dd37d103f'
                },
                items: [
                    {
                        id: 'f2elPY9jCu',
                        scene: '用药提醒',
                        condition: '',
                        default: true,
                        name: '询问服药',
                        content: '您平时有吃药吗，咱们这边也可以做一个用药提醒，以后就不担心忘记吃药了。',
                        index: 1,
                        level: 1,
                        labels: ['用药'],
                        action: 'setMedicineReminder',
                        endOfScene: false,
                        sceneId: 'f399dcc10cc256d383126c2dd37d103f'
                    }
                ]
            },
            {
                index: 2,
                default: {
                    id: 'j6QRmAKPjR',
                    scene: '用药提醒',
                    condition: '拒绝推荐',
                    default: true,
                    name: '日常沟通',
                    content: '那您可以自己建一个闹钟，或者让家里人注意一下，还是要按时服药的。',
                    index: 2,
                    level: 1,
                    labels: ['寒暄'],
                    endOfScene: false,
                    sceneId: 'f399dcc10cc256d383126c2dd37d103f'
                },
                items: [
                    {
                        id: 'j6QRmAKPjR',
                        scene: '用药提醒',
                        condition: '拒绝推荐',
                        default: true,
                        name: '日常沟通',
                        content: '那您可以自己建一个闹钟，或者让家里人注意一下，还是要按时服药的。',
                        index: 2,
                        level: 1,
                        labels: ['寒暄'],
                        endOfScene: false,
                        sceneId: 'f399dcc10cc256d383126c2dd37d103f'
                    }
                ]
            },
            {
                index: 3,
                default: {
                    id: 'yVLHEaNc7s',
                    scene: '用药提醒',
                    condition: '等待人多',
                    default: true,
                    name: '转其他专员',
                    content: '请稍等， 我给您联系其他专员进行用药添加，她对药品方面比较了解。',
                    index: 3,
                    level: 1,
                    labels: ['引导'],
                    endOfScene: false,
                    sceneId: 'f399dcc10cc256d383126c2dd37d103f'
                },
                items: [
                    {
                        id: 'H-rUmgcC_v',
                        scene: '用药提醒',
                        condition: '等待人多',
                        default: true,
                        name: '引导分流',
                        content: '您可以先扫码测关注一下公众号，上面就可以看到您的个人信息了，您可以先想想平时吃什么药，填写一下，稍后我来给咱做个记录。',
                        index: 3,
                        level: 1,
                        labels: ['引导'],
                        endOfScene: false,
                        sceneId: 'f399dcc10cc256d383126c2dd37d103f',
                        action: 'showConsCode'
                    },
                    {
                        id: 'yVLHEaNc7s',
                        scene: '用药提醒',
                        condition: '等待人多',
                        default: true,
                        name: '转其他专员',
                        content: '请稍等， 我给您联系其他专员进行用药添加，她对药品方面比较了解。',
                        index: 3,
                        level: 1,
                        labels: ['引导'],
                        endOfScene: false,
                        sceneId: 'f399dcc10cc256d383126c2dd37d103f',
                        action: 'showConsCode'
                    }
                ]
            },
            {
                index: 4,
                default: {
                    id: 'V1Mvs0Xalj',
                    scene: '用药提醒',
                    condition: '',
                    default: true,
                    name: '添加用药',
                    content: '您***药一周吃几次呀，每天什么时候时候吃啊？',
                    index: 4,
                    level: 1,
                    labels: ['增加用药'],
                    endOfScene: false,
                    sceneId: 'f399dcc10cc256d383126c2dd37d103f'
                },
                items: [
                    {
                        id: 'V1Mvs0Xalj',
                        scene: '用药提醒',
                        condition: '',
                        default: true,
                        name: '添加用药',
                        content: '您***药一周吃几次呀，每天什么时候时候吃啊？',
                        index: 4,
                        level: 1,
                        labels: ['增加用药'],
                        endOfScene: false,
                        sceneId: 'f399dcc10cc256d383126c2dd37d103f'
                    }
                ]
            },
            {
                index: 5,
                default: {
                    id: 'uNDm-8lA6X',
                    scene: '用药提醒',
                    condition: '',
                    default: true,
                    name: '添加用药',
                    content: '我都给咱加上了，***隔一天吃一次，早上，晚上各一粒，您看一下有遗漏吗？',
                    index: 5,
                    level: 1,
                    labels: ['总结'],
                    endOfScene: true,
                    sceneId: 'f399dcc10cc256d383126c2dd37d103f'
                },
                items: [
                    {
                        id: 'uNDm-8lA6X',
                        scene: '用药提醒',
                        condition: '',
                        default: true,
                        name: '添加用药',
                        content: '我都给咱加上了，***隔一天吃一次，早上，晚上各一粒，您看一下有遗漏吗？',
                        index: 5,
                        level: 1,
                        labels: ['总结'],
                        endOfScene: true,
                        sceneId: 'f399dcc10cc256d383126c2dd37d103f'
                    }
                ]
            }
        ]
    },
    {
        id: '0dcbf60dcae25ba7187a2c44e94e1046',
        name: '健康日记',
        indexes: [
            {
                index: 1,
                default: {
                    id: '0zUibeqZFj',
                    scene: '健康日记',
                    condition: '',
                    default: true,
                    name: '增加健康日记',
                    content: '我看咱血压不太稳定，可以增加一个健康日记，每天提醒测血压，测完了也能记录数值，以后那天想看了随时都能再看。',
                    index: 1,
                    level: 1,
                    labels: ['引导'],
                    action: 'setDiaries',
                    endOfScene: false,
                    sceneId: '0dcbf60dcae25ba7187a2c44e94e1046'
                },
                items: [
                    {
                        id: '0zUibeqZFj',
                        scene: '健康日记',
                        condition: '',
                        default: true,
                        name: '增加健康日记',
                        content: '我看咱血压不太稳定，可以增加一个健康日记，每天提醒测血压，测完了也能记录数值，以后那天想看了随时都能再看。',
                        index: 1,
                        level: 1,
                        labels: ['引导'],
                        action: 'setDiaries',
                        endOfScene: false,
                        sceneId: '0dcbf60dcae25ba7187a2c44e94e1046'
                    }
                ]
            },
            {
                index: 2,
                default: {
                    id: '2zHtUOYeG9',
                    scene: '健康日记',
                    condition: '拒绝推荐',
                    default: true,
                    name: '日常沟通',
                    content: '那您可以自己建一个备忘录，或者让家里人注意一下。',
                    index: 2,
                    level: 1,
                    labels: ['寒暄'],
                    endOfScene: false,
                    sceneId: '0dcbf60dcae25ba7187a2c44e94e1046'
                },
                items: [
                    {
                        id: '2zHtUOYeG9',
                        scene: '健康日记',
                        condition: '拒绝推荐',
                        default: true,
                        name: '日常沟通',
                        content: '那您可以自己建一个备忘录，或者让家里人注意一下。',
                        index: 2,
                        level: 1,
                        labels: ['寒暄'],
                        endOfScene: false,
                        sceneId: '0dcbf60dcae25ba7187a2c44e94e1046'
                    }
                ]
            },
            {
                index: 3,
                default: {
                    id: '7cEJNT-nZl',
                    scene: '健康日记',
                    condition: '',
                    default: true,
                    name: '自定义健康日记',
                    content: '您最关心的这几项，我给新建了一个健康日记组合，每天一打开就能选择记录了。',
                    index: 3,
                    level: 1,
                    labels: ['健康日记'],
                    endOfScene: true,
                    sceneId: '0dcbf60dcae25ba7187a2c44e94e1046'
                },
                items: [
                    {
                        id: '7cEJNT-nZl',
                        scene: '健康日记',
                        condition: '',
                        default: true,
                        name: '自定义健康日记',
                        content: '您最关心的这几项，我给新建了一个健康日记组合，每天一打开就能选择记录了。',
                        index: 3,
                        level: 1,
                        labels: ['健康日记'],
                        endOfScene: true,
                        sceneId: '0dcbf60dcae25ba7187a2c44e94e1046'
                    }
                ]
            }
        ]
    },
    {
        id: 'a2548906aeb06c7c60b664b8fd38b0f5',
        name: '计划预览',
        indexes: [
            {
                index: 1,
                default: {
                    id: '2lt7dLjLAa',
                    scene: '计划预览',
                    condition: '',
                    default: true,
                    name: '展示一周计划',
                    content: '给您的健康计划做好了，您看一下还有没有完善的？',
                    index: 1,
                    level: 1,
                    labels: ['总结'],
                    action: 'previewPlans',
                    endOfScene: false,
                    sceneId: 'a2548906aeb06c7c60b664b8fd38b0f5'
                },
                items: [
                    {
                        id: '2lt7dLjLAa',
                        scene: '计划预览',
                        condition: '',
                        default: true,
                        name: '展示一周计划',
                        content: '给您的健康计划做好了，您看一下还有没有完善的？',
                        index: 1,
                        level: 1,
                        labels: ['总结'],
                        action: 'previewPlans',
                        endOfScene: false,
                        sceneId: 'a2548906aeb06c7c60b664b8fd38b0f5'
                    }
                ]
            },
            {
                index: 2,
                default: {
                    id: 'huQj20Pwom',
                    scene: '计划预览',
                    condition: '引导结束',
                    default: true,
                    name: '完成全流程',
                    content: '健康也是需要管理的，我和您一块按照健康计划，让咱的身体越来越健康。',
                    index: 2,
                    level: 1,
                    labels: ['寒暄'],
                    endOfScene: false,
                    sceneId: 'a2548906aeb06c7c60b664b8fd38b0f5'
                },
                items: [
                    {
                        id: 'huQj20Pwom',
                        scene: '计划预览',
                        condition: '引导结束',
                        default: true,
                        name: '完成全流程',
                        content: '健康也是需要管理的，我和您一块按照健康计划，让咱的身体越来越健康。',
                        index: 2,
                        level: 1,
                        labels: ['寒暄'],
                        endOfScene: false,
                        sceneId: 'a2548906aeb06c7c60b664b8fd38b0f5'
                    }
                ]
            },
            {
                index: 3,
                default: {
                    id: 'ySd3O1jz9F',
                    scene: '计划预览',
                    condition: '日常用语',
                    default: true,
                    name: '沟通用语',
                    content: '您要有什么问题随时过来就可以。',
                    index: 3,
                    level: 1,
                    labels: ['寒暄'],
                    endOfScene: true,
                    sceneId: 'a2548906aeb06c7c60b664b8fd38b0f5'
                },
                items: [
                    {
                        id: 'ySd3O1jz9F',
                        scene: '计划预览',
                        condition: '日常用语',
                        default: true,
                        name: '沟通用语',
                        content: '您要有什么问题随时过来就可以。',
                        index: 3,
                        level: 1,
                        labels: ['寒暄'],
                        endOfScene: true,
                        sceneId: 'a2548906aeb06c7c60b664b8fd38b0f5'
                    }
                ]
            }
        ]
    }
]
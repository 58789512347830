import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import ColorDict from './utils/dealColor'
import './main.css'
// import '@mdi/font/css/materialdesignicons.css'
import {pop} from './utils/pop'
import VueClipboard from 'vue-clipboard2'
// 将自动注册所有组件为全局组件
// import dataV from '@jiaminghi/data-view'

// Vue.use(dataV)
Vue.use(VueClipboard)

import Confirm from './components/utils/Confirm'

Vue.use(Confirm);

Vue.config.productionTip = false

Vue.prototype.$pop = (param, color = 'success') => {
    return pop(param, color)
}

Vue.config.productionTip = false


const $getColorCode = className => {
    let item = ColorDict.find(c => c.name === className.replace(/--|text/g, ''))
    return item && item.code
}

Vue.prototype.$getColorCode = $getColorCode

store.state.categoryColorMap = {
    '中草药': $getColorCode('green'),
    '方剂': $getColorCode('primary'),
    '症状': $getColorCode('red'),
    '药品': $getColorCode('blue'),
    '药膳食疗': $getColorCode('orange'),
    '针灸穴位': $getColorCode('teal'),
    '名词': $getColorCode('light-blue'),
}

// router.beforeEach((to, from, next) => {
//     // console.log(to)
//     window.document.title = to.meta.title
//     if (to.name !== 'Home' && !to.params.shortUrl) next({name: 'Home'})
//     else next()
// })

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')

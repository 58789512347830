import Vue from 'vue'

export function pop(param, color) {
    import(`../components/utils/Tip.vue`).then(component => {
            if (typeof param === 'string') param = {text: param}
            let Component = Vue.extend(component.default);
            let vm = new Component({
                el: document.createElement("div"),
                propsData: {
                    pop: {value: true, color, ...param}
                },
            }).$mount();
            document.getElementById('app').appendChild(vm.$el)
        }
    )
}


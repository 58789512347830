import component from './Confirm.vue'


export default function (Vue) {
    const Constructor = Vue.extend(component)
    const Instance = new Constructor();
    Instance.$mount()

    Vue.prototype.$confirm = ({title, text, persistent = false, image, card, ok, cancel}) => {
        document.getElementById('app').appendChild(Instance.$el)
        Instance.show = true
        Instance.title = title
        Instance.persistant = persistent
        Instance.text = text
        Instance.image = image
        Instance.card = card || {}
        Instance.ok = ok || '确定'
        Instance.cancel = cancel || '取消'

        return Instance.confirm()
            .then(val => {
                return Promise.resolve(val);
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }
}
<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data: () => ({
    entityId: null
  }),
  computed: {},
  mounted() {
  },
  methods: {
    onResize() {
      console.log('Resizing...')
      const windowSize = {x: window.innerWidth, y: window.innerHeight}
      this.$store.commit('judgeMobile', windowSize)
    }
  }
};
</script>

<style>

::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar {
  width: 0px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  min-height: 28px;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
</style>

<template>
  <v-overlay :value="show" z-app="9999" style="z-index: 10000!important;">
    <template v-if="show">
      <v-img v-if="image" :src="image" contain height="100vh" width="100vh">
        <v-btn fab fixed top right dark small color="red" @click="show=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-img>
      <v-card v-else
              min-width="270"
              max-width="80vh"
              :width="card.width"
              :height="card.height"
              :color="card.color"
              :dark="card.dark || !!card.color"
              :light="!card.dark && !card.color"
      >
        <v-card-title class="subtitle-1">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <div v-html="text"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="ok!==false" :color="okColor" text @click="emitOk">
            {{ typeof ok === 'string' ? ok : (ok.text || '确定') }}
          </v-btn>
          <v-btn v-if="cancel!==false" :color="cancelColor" text @click="emitCancel">
            {{ typeof cancel === 'string' ? cancel : cancel.text || '取消' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-overlay>
</template>

<script>
export default {
  name: "Confirm",
  data: () => ({
    show: false,
    title: null,
    text: null,
    image: null,
    card: {},
    ok: {},
    cancel: {},
    resolve: '',
    reject: '',
    promise: '', // 保存promise对象

  }),
  computed: {
    okColor() {
      if (!this.ok) return
      if (this.card.color || this.card.dark) return 'grey lighten-4'
      else return this.ok.color || 'success'
    },
    cancelColor() {
      if (!this.cancel) return
      if (this.card.color || this.card.dark) return 'grey lighten-2'
      else return this.cancel.color || 'grey'
    }
  },
  watch: {},
  methods: {
    emitOk() {
      this.show = false;
      this.resolve('submit');
    },
    // 取消,将promise断定为reject状态
    emitCancel() {
      this.show = false;
      this.reject('cancel');
    },
    //显示confirm弹出,并创建promise对象，给父组件调用
    confirm() {
      this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
      return this.promise; //返回promise对象,给父级组件调用
    }
  }

}
</script>

<style scoped>

</style>